<template>
  <ManagePlatformFee dialog-name="Platform Fees" />
</template>
<script>
import ManagePlatformFee from '@/views/app/manage/PlatformFee'

export default {
  name: 'PlatformFeeWrapper',
  components: {
    ManagePlatformFee
  }
}
</script>
